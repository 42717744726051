export const btnStyle = {
    padding: 10,
    marginTop: 10,
    width: '100%',
    border: '2px solid #7F5BEB',
};

export const inputStyle = {
    width: '100%',
    // border: '1px solid #333333',
    borderRadius: '5px'
};

export const iconStyle = {
    color: '#333333'
};